@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    scroll-behavior: smooth;
}

@layer base {
    * {
        @apply m-0 p-0 box-border;
    }

    body {
        @apply m-0 p-0 font-kanit-regular;
    }
}

@layer utilities {
    .font-kanit-regular {
        font-family: "Kanit", sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .font-pt-serif-regular {
        font-family: "PT Serif", serif;
        font-weight: 400;
        font-style: normal;
      }
}